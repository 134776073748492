.int__mobilita-main {
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
}

.int__mobilita-title {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #9A1413;
    text-align: left;
    padding-bottom: 1rem;
}
.int__mobilita-timeline{
    margin: 0rem;
    padding: 0rem;
}
.int__mobilita-linebox-link {
    padding-right: 0.5rem;
    font-family: var(--font-family);
    cursor: pointer;
    margin: 1.5em 0;
    padding-bottom: 0;
}

.int__mobilita-linebox {
    padding-right: 0.5rem;
    font-family: var(--font-family);
    margin: 1.5em 0;
    padding-bottom: 0;
}

.int__mobilita-data{
    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: 800 !important;
    font-size: 0.9rem !important;
}
.int__mobilita-boxsubtitle {
    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: 00 !important;
    font-size: 1rem !important;
    margin-bottom: 1rem;
    color: #4f4f4f !important;
}
.int__mobilita-boxtitle {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
}

.int__mobilita-boxsubsubtitle{ 
    font-weight: 400 !important;
    font-size: 0.8rem;
}

.int__mobilita-posizioni{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500 !important;
    font-size: 0.8rem !important;
}


@media screen and (max-width:1050px) {
    .int__mobilita-main {
        padding-top: 1rem;
    }
    .int__mobilita-title {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        padding-bottom: 1rem;
    }
    .int__mobilita-data{
        font-style: normal;
        font-weight: 800 !important;
        font-size: 0.9rem !important;
    }
    .int__mobilita-boxsubtitle {
        font-weight: 00 !important;
        font-size: 1rem !important;
        margin-bottom: 1rem;
    }
    .int__mobilita-boxtitle {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
    }    
    .int__mobilita-boxsubsubtitle{ 
        font-weight: 400 !important;
        font-size: 0.7rem;
    }
    .int__mobilita-posizioni{
        font-weight: 500 !important;
        font-size: 0.7rem !important;
    }
}


@media screen and (max-width:750px) {
    .int__mobilita-main {
        padding-top: 1rem;
    }
    .vertical-timeline-element-icon{
        width: 25px !important;
        height: 25px !important;
        left: 7px !important;
    }
    .vertical-timeline-element-content{
        margin-left: 45px !important;
    }
    .int__mobilita-title {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        padding-bottom: 1rem;
    }
    .int__mobilita-data{
        font-style: normal;
        font-weight: 800 !important;
        font-size: 0.8rem !important;
    }
    .int__mobilita-boxsubtitle {
        font-weight: 00 !important;
        font-size: 0.9rem !important;
        margin-bottom: 1rem;
    }
    .int__mobilita-boxtitle {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 500;
        font-size: 0.9rem;
    }    
    .int__mobilita-boxsubsubtitle{ 
        font-weight: 400 !important;
        font-size: 0.6rem;
    }
    .int__mobilita-posizioni{
        font-weight: 500 !important;
        font-size: 0.6rem !important;
    }
}

@media screen and (max-width:550px) {
     
}