.int__ricerca-contenuti-testo{
    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    font-size: 16px;
    color: #666;
    width: 100%;
    text-align: justify;
}

.int__ricerca-global-container-vert {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2rem;
}
.int__ricerca-global-container-horiz{
    display: flex;
    flex-direction: column;
    width: 1920px;
    padding: 1rem 7rem;
}

.int__ricerca-numerorisultati{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin: 0.5rem 0rem 2rem;
}

.int__ricerca-item-elenco-link{
    padding: 0rem 7rem;
    max-width: 1920px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #9A1413;
    margin: 0.5rem 0rem 2rem;
    display: block;
}

.int__ricerca-item-elenco-link-cursor{
    padding: 0rem 7rem;
    max-width: 1920px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #9A1413;
    margin: 0.5rem 0rem 2rem;
    display: block;
    cursor: pointer;
}

.int__ricerca-item-contenuto{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #9A1413;
    margin: 0.5rem 0rem;
    display: flex; 
}

.int__ricerca-title-container-title{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 20px;
    color: #9A1413;
    margin-bottom: 2rem;
}

.int__ricerca-section-container{
    margin-bottom: 3rem;
}

.int__ricerca-title-name{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 20px;
    color: #4f4f4f;
    margin-bottom: 0.5em;
}

.int__ricerca-item-img{
    padding-left: 1rem;
}

.int__ricerca-item-img img{
    height: 18px;
}

.int__ricerca_link_foto{
    color: #9A1413;
}

@media screen and (max-width:1050px) {
    .int__ricerca-item-elenco-link{
        padding: 0rem 2rem;
        max-width: 1920px;
        font-weight: 600;
        font-size: 16px;
        margin: 0.5rem 0rem 1.5rem;
        display: block;
    }
    .int__ricerca-global-container-vert{
        margin-bottom: 0rem;
    }
    .int__ricerca-global-container-horiz{
        padding: 1rem 2rem;
    }
    .int__ricerca-contenuti-testo{
        font-weight: 400;
        line-height: 20px;
        font-size: 14px;
    }
    .int__ricerca-title-container-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 18px;
        margin-bottom: 1rem;
    }
    .int__ricerca-section-container{
        margin-bottom: 1rem;
    }
    .int__ricerca-title-name{
        font-weight: 600;
        font-size: 20px;
        line-height: 18px;
    }
    .int__ricerca-item-contenuto{
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        margin: 0.5rem 0rem;
    }
    .int__ricerca-item-img img{
        height: 14px;
    }
}

@media screen and (max-width:750px) {
    .int__ricerca-item-elenco-link{
        padding: 0rem 2rem;
        max-width: 1920px;
        font-weight: 600;
        font-size: 15px;
        margin: 0.5rem 0rem 1.5rem;
        display: block;
    }
    .int__ricerca-global-container-vert{
        margin-bottom: 0rem;
    }
    .int__ricerca-global-container-horiz{
        padding: 1rem 2rem;
    }
    .int__ricerca-contenuti-testo{
        font-weight: 500;
        line-height: 18px;
        font-size: 12px !important;
        width: 100%;
    }
    .int__ricerca-title-container-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 16px;
        margin-bottom: 1rem;
    }
    .int__ricerca-section-container{
        margin-bottom: 1rem;
    }
    .int__ricerca-title-name{
        font-weight: 600;
        font-size: 18px;
        line-height: 16px;
    }
    .int__ricerca-item-contenuto{
        font-weight: 500;
        font-size: 11px;
        line-height: 12px;
        margin: 0.5rem 0rem;
    }
    .int__ricerca-item-img img{
        height: 13px;
    }
}

@media screen and (max-width:550px) {
    .int__ricerca-item-elenco-link{
        padding: 0rem 1rem;
        max-width: 1920px;
        font-weight: 600;
        font-size: 15px;
        margin: 0.5rem 0rem 1.5rem;
        display: block;
    }
    .int__ricerca-global-container-vert{
        margin-bottom: 0rem;
    }
    .int__ricerca-contenuti-testo{
        font-weight: 500;
        line-height: 18px;
        font-size: 12px !important;
        width: 100%;
    }
    .int__ricerca-title-container-title {
        font-weight: 700;
        font-size: 15px;
        line-height: 13px;
        margin-bottom: 1rem;
    }
    .int__ricerca-section-container{
        margin-bottom: 1rem;
    }
    .int__ricerca-global-container-horiz{
        padding: 1rem 1rem;
    }
    .int__ricerca-title-name{
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
    }
    .int__ricerca-item-contenuto{
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        margin: 0.5rem 0rem;
    }
    .int__ricerca-item-img img{
        height: 13px;
    }   
}