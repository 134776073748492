.int__ricerca-container{
    display: flex;
    flex-direction: row;
    justify-content: left;
}
.int__ricerca-form-container{
    display: flex;
    flex-direction: column;
    margin: 1rem 0rem;
}
.int__ricerca-form-container p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 20px;
    color: #9A1413;
    padding-left: 1rem;
}

.int__ricerca-form{
    display: flex;
    flex-direction: row;
    padding: 0rem;
    align-items: center;
}

.int__ricerca-form input {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    border: 0;
    padding: 1rem;
    background: #E5E5E5;
    width: 500px;
    border-radius: 5px 5px 5px 5px;
}

.int__ricerca-form-submit{
    padding-left: 7rem;
}

.int__ricerca-form-submit input {
    font-family: var(--font-family);
    border-radius: 6px;
    background: #9A1413;
    color: white;
    border: none;
    width: 150px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    box-shadow: 2px 2px 2px 1px #ccc;
}

/* Elenco */
.int__ricerca-letterecontainer{
    padding-top: 2rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.int__ricerca-lettere-button{
    width: 30px;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    background: #9A1413;
    color: white;
    border: none;
    padding: 0.5rem 0.5rem;
    cursor: pointer;
    box-shadow: 2px 2px 2px 1px #ccc;
}

.int__ricerca-lettere-button p{
    font-family: var(--font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.int__ricerca-elenco{
    padding: 1rem 0rem;
}

.int__ricerca-testuale-item-elenco-link{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #9A1413;
    margin: 0.5rem 0rem 2rem;
    display: block;
}

.int__ricerca-elenco-iniziale{
    display: flex;
    flex-direction: column;
    padding: 1rem 0rem;
}

.int__ricerca-elenco-lettera{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 20px;
    color: #9A1413;
    padding: 1rem 0rem;
    text-transform: capitalize;
}
.int__ricerca-hr{
    width: 100%;
    background-color:#9A1413;
    border-radius: 6px;
    border: 2px solid #9A1413;
}

.int__ricercatestuale-numerorisultati{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    padding: 1rem 0rem;
}

@media screen and (max-width:1050px) {
    .int__ricerca-form input{
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        padding: 0.5rem;
        width: 350px;
        border-radius: 5px 5px 5px 5px;
    }
    .int__ricerca-form{
        padding-left: 0rem;
    }
    .int__ricerca-form-submit {
        padding-left: 5rem;
    }
    .int__ricerca-form-submit input {
        border-radius: 6px;
        width: 150px;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
    }
    .int__ricercatestuale-numerorisultati{
        font-weight: 400;
        font-size: 15px;
        padding: 1rem 0rem;
    }
    .int__ricerca-testuale-item-elenco-link{
        font-weight: 600;
        font-size: 18px;
        margin: 0.5rem 0rem 1.5rem;
        display: block;
    }
}

@media screen and (max-width:750px) {
    .int__ricerca-form{
        padding: 0rem;
    }
    .int__ricerca-form input{
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        padding: 0.5rem;
        width: 250px;
        border-radius: 5px 5px 5px 5px;
    }
    .int__ricerca-form-submit {
        padding-left: 2rem;
    }
    .int__ricerca-form-submit input {
        border-radius: 6px;
        width: 80px;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
    }
    .int__ricercatestuale-numerorisultati{
        font-weight: 400;
        font-size: 15px;
        padding-top: 0rem; 
        padding-bottom: 1rem;
    }
    .int__ricerca-testuale-item-elenco-link{
        font-weight: 600;
        font-size: 16px;
        margin: 0.5rem 0rem 1.5rem;
        display: block;
    }
}

@media screen and (max-width:550px) {
    .int__ricerca-form{
        display: flex;
        flex-direction: row;
        padding: 0rem;
        align-items: flex-start;
    }
    .int__ricerca-form input{
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        padding: 0.5rem;
        width: 180px;
        border-radius: 5px 5px 5px 5px;
    }
    .int__ricerca-form-submit {
        padding-left: 1rem;
    }
    .int__ricerca-form-submit input {
        border-radius: 6px;
        width: 80px;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
    }
    .int__ricercatestuale-numerorisultati{
        font-weight: 400;
        font-size: 13px;
        padding-top: 0rem; 
        padding-bottom: 1rem;
    }
    .int__ricerca-testuale-item-elenco-link{
        font-weight: 550;
        font-size: 14px;
        margin: 0.5rem 0rem 1.5rem;
        display: block;
    }
}