.int__maincontainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}
.int__maincontainer-horiz{
    display: flex;
    padding: 0rem 7rem;
    padding-bottom: 1rem;
    max-width: 1920px;
}

@media screen and (max-width:1050px) {
    .int__maincontainer-horiz{
        display: flex;
        padding: 0rem 2rem;
        padding-bottom: 1rem;
        max-width: 1920px;
    }
}
@media screen and (max-width:750px) {
    .int__maincontainer-horiz{
        display: flex;
        padding: 0rem 2rem;
        padding-bottom: 1rem;
        max-width: 1920px;
    }
}

@media screen and (max-width:615px) {
    .int__maincontainer-horiz{
        display: flex;
        flex-direction: column-reverse;
    }
}