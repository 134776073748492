.int__video-about-us-main {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap
}

.int__video-about-us-title-box {
    font-family: var(--font-family);
    text-align: left;
    font-weight: 500;
    font-size: 15px;
    padding: 2rem 1rem;
}

.int__video-about-us-title {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #9A1413;
    text-align: left;
    padding-bottom: 1rem;
}

.int__video-about-us-player-wrapper{
    width: 216px;
    height: 120px;

}
.int__video-about-us-descr{
    font-family: var(--font-family);
    text-align: left;
    font-weight: 500;
    font-size: 15px;
    padding: 0rem 1rem;
    padding-top: 1rem; 
}
.int__video-about-us-modal-descr{
    font-family: var(--font-family);
    text-align: left;
    font-weight: 500;
    font-size: 15px;
    padding: 0rem 1rem;
    padding-top: 1rem; 
}

@media screen and (max-width:1050px) {
    .int__video-about-us-title-box {
        font-weight: 500;
        font-size: 15px;
        padding: 1rem 1rem;
    }
    
    .int__video-about-us-title {
        font-weight: 500;
        font-size: 15px;
        padding-bottom: 1rem;
    }
    
    .int__video-about-us-player-wrapper{
        width: 216px;
        height: 120px;
    
    }
    .int__video-about-us-descr{
        font-weight: 500;
        font-size: 15px;
        padding: 0rem 1rem;
        padding-top: 1rem;  
    }
    .int__video-about-us-modal-descr{
        font-weight: 500;
        font-size: 15px;
        padding: 0rem 1rem;
        padding-top: 1rem; 
    }
}

@media screen and (max-width:750px) {
    .int__video-about-us-title {
        font-weight: 500;
        font-size: 13px;
        padding-bottom: 0.5rem;
    }
    
    .int__video-about-us-player-wrapper{
        width: 150px;
        height: 83px;
    
    }
    .int__video-about-us-descr{
        font-weight: 500;
        font-size: 12px;
        padding: 0rem 1rem;
        padding-top: 1rem;  
    }    
    .int__video-about-us-modal-descr{
        font-weight: 500;
        font-size: 12px;
        padding: 0rem 1rem;
        padding-top: 1rem; 
    }
}

@media screen and (max-width:550px) {
    .int__video-about-us-title {
        font-weight: 500;
        font-size: 12px;
        padding-bottom: 0.5rem;
    }
    .int__video-about-us-player-wrapper{
        width: 110px;
        height: 61px;
    }
    .int__video-about-us-descr{
        font-weight: 500;
        font-size: 12px;
        padding: 0rem 1rem;
        padding-top: 1rem;   
    }      
    .int__video-about-us-modal-descr{
        font-weight: 400;
        font-size: 10px;
        padding: 0rem 1rem;
        padding-top: 1rem; 
    }  
}