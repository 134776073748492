.int__footer_container{
    background: #9A1413;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
}

.int__footer_buttons-container{
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    flex:1;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.int__footer_button{
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border-radius:5px;
    cursor: pointer;
    background-color: #9A1413;
    box-sizing: border-box;
    border: none;
}

.int__footer_button:hover{
    color: grey;
}

.int__footer_loghi{
    display: flex;
    justify-content: center;
}

.int__footer_loghi img{
    height: 175px;
}

.int__footer_copyright_prima-pagina{
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'liga' off;
    color: #FFFFFF;
}

.int__footer_copyright_prima-pagina a:hover {
    color: #000;
}

.int__footer-logofup-container{
    display: flex;
}
.int__footer_logofup{
    margin-right: 100px;
}

.int__footer_logofup img{
    max-height: 45px;
}

.int__footer_copyright{
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'liga' off;
    color: rgba(255,255,255,0.6);
}

.int__footer_copyright a:hover{
    color: #FFFFFF;
}


@media screen and (max-width: 1050px) {
    .int__footer_loghi img{
        width: 600px;
        height: 100%;
    }
    .int__footer_copyright_prima-pagina p{
        font-weight: 300;
        font-size: 10px;
    }
}

@media screen and (max-width: 700px) {
    .int__footer_loghi img{
        width: 400px;
        height: 100%;
    }
    .int__footer_copyright_prima-pagina p{
        font-weight: 300;
        font-size: 10px;
    }    
}

@media screen and (max-width: 550px) {
    .int__footer_loghi img{
        width: 300px;
        height: 100%;
    }
    .int__footer_copyright_prima-pagina p{
        font-weight: 300;
        font-size: 10px;
    }    
}