.int__video-main {
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
}

.int__video-title {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #9a1413;
  text-align: left;
  padding-bottom: 1rem;
}
.int__video-title-box:not(:nth-child(2)) {
  padding-top: 1rem;
}
.int__video-title-box {
  font-family: var(--font-family);
  text-align: left;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  padding: 0rem 1rem;
  padding-top: 0.1rem;
  display: flex;
  max-width: 476px;
}
.int__video-title-box img {
  width: 42px;
  height: 42px;
  margin-right: 0.7rem;
}
.int__video-title-box:hover {
  color: #9a1413;
}

.int__video-image {
  max-width: 50%;
  padding: 0.1rem;
}

.int__video-modal {
  text-align: center;
  height: 350px;
}

.int__video-modal-descr {
  font-family: var(--font-family);
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  padding: 0rem 1rem;
  padding-top: 1rem;
}

@media screen and (max-width: 1250px) {
}

@media screen and (max-width: 1050px) {
  .int__video-main {
    padding-top: 1rem;
  }
  .int__video-title {
    font-weight: 500;
    font-size: 13px;
    padding-bottom: 1rem;
  }
  .int__video-title-box {
    font-weight: 500;
    font-size: 13px;
    padding: 0rem 1rem;
    padding-top: 0.1rem;
  }
}

@media screen and (max-width: 900px) {
  .int__video-main {
    padding-top: 1rem;
  }
  .int__video-title {
    font-weight: 500;
    font-size: 11px;
    padding-bottom: 1rem;
  }
  .int__video-title-box {
    font-weight: 500;
    font-size: 11px;
    padding: 0rem 1rem;
    padding-top: 0.1rem;
  }
}

@media screen and (max-width: 750px) {
  .int__video-modal {
    height: 150px;
  }
  .int__video-modal-descr {
    font-family: var(--font-family);
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    padding: 0rem 0.5rem;
    padding-top: 0.5rem;
  }
}
