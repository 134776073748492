.int__title {
    min-height: 158px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 3rem;    
}

.int__title-container{
    display: flex;
    flex-direction: column;
    width: 1920px;
    padding: 0.3rem 7rem;
}
.int__title-box{
    display: flex;
    justify-content: flex-start;
}

.int__title-name {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    color: #111111;
}

.int__title-date {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    color: #111111;
    margin-left: 1rem;
}

.int_title_subtitle {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    padding-top: 1rem;
    color: #111111;
}

.int__intestazioni-titlebox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.int__intestazioni-title{
    padding: 1rem 0rem;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #000;
}

.int__intestazioni-names{
    padding: 1rem;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #9A1413;
}


@media screen and (max-width:1050px) {
    .int__title {
        min-height: 128px;
        padding-bottom: 0.8rem;    
    }
    .int__title-container{
        padding: 0.3rem 2rem;
    }
    .int__title-name{
        font-weight: 550;
        font-size: 27px;
    }
    .int__title-date{
        font-weight: 300;
        font-size: 24px;
        margin-left: 1rem;
    }
    .int_title_subtitle {
        font-weight: 200;
        font-size: 18px;
        padding-top: 1rem;
    }    
    .int__intestazioni-title{
        padding: 1rem 0rem;
        font-weight: 400;
        font-size: 15px;
    }
    .int__intestazioni-names{
        padding: 0.5rem;
        font-weight: 400;
        font-size: 15px;
    }
}

@media screen and (max-width:750px) {
    .int__title {
        min-height: 88px;
        padding-bottom: 0.5rem;    
    }
    .int__title-container{
        padding: 0.3rem 2rem;
    }
    .int__title-name{
        font-weight: 500;
        font-size: 22px;
    }
    .int__title-date{
        font-weight: 300;
        font-size: 20px;
        margin-left: 1rem;
    }
    .int_title_subtitle {
        font-weight: 200;
        font-size: 13px;
        padding-top: 1rem;
    }    
    .int__intestazioni-title{
        padding: 1rem 0rem;
        font-weight: 400;
        font-size: 11px;
    }
    .int__intestazioni-names{
        padding: 1rem;
        font-weight: 400;
        font-size: 11px;
    }     
}

@media screen and (max-width:550px) {
    .int__title {
        min-height: 68px;
        padding-bottom: 0.2rem;    
    }
    .int__title-container{
        padding: 0.3rem 1rem;
    }
    .int__title-name{
        font-weight: 500;
        font-size: 20px;
    }
    .int__title-date{
        font-weight: 300;
        font-size: 19px;
        margin-left: 1rem;
    }
    .int_title_subtitle {
        font-weight: 200;
        font-size: 12px;
        padding-top: 1rem;
    }    
    .int__intestazioni-title{
        padding: 1rem 0rem;
        font-weight: 400;
        font-size: 11px;
    }
    .int__intestazioni-names{
        padding: 1rem;
        font-weight: 400;
        font-size: 11px;
    }       
}