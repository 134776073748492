.int__body-main {
  text-align: center;
}

.int__pdf-pages {
  font-family: var(--font-family);
  font-style: normal;
  color: #4f4f4f;
  font-weight: 700;
  font-size: 15px;
  padding-bottom: 1rem;
}

.int__pdf-pages p {
  padding: 1rem;
}

.int__pdf-pages-button-container {
  display: flex;
  flex-direction: column;
}
.int__pdf-pages-button {
    border: none;
    background:white;
    cursor: pointer;    
}
.int__pdf-lastpages-button {
  margin-top: 2rem;
  border: none;
  background:white;
  cursor: pointer;
  display: flex;
  flex-direction: row;    
}
.int__pdf-icons {
  max-height: 25px;
  max-width: 25px;
}

.int__pdf-buttons{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.int__archiviabbreviazioni{
  display: flex;
  justify-content: center;
}

.int__archiviabbreviazioni-button {
  font-family: var(--font-family);
  margin-right: 10px;
  border-radius: 6px;
  background: #9A1413;
  color: white;
  border: none;
  padding: 0.5rem 0.5rem;
  width: 150px;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 2px 2px 2px 1px #ccc;

}
/* For pdf */
.react-pdf__Document {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.react-pdf__Page {
    font-family: var(--font-family);
}

.react-pdf__Page__textContent {
    user-select: none;
    box-shadow: 1px 1px 3px 1px #ccc;
    border-radius: 5px;
}
  
.react-pdf__Page__annotations.annotationLayer a {
  cursor: default;
  pointer-events: none;
}
  
.react-pdf__Page__canvas {
    font-family: var(--font-family);
    margin: 0 auto;
}
  
/* For all pages */
.all-page-container {
    overflow: auto;
}

.int__pdf-title{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 0rem 2rem 2rem 2rem;
  width: 100%;
  color: #4f4f4f;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
}

.int__pdf-search-results-container{
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  width: 90%;
}

.int__pdf-search-results-container p{
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  padding: 2rem;
}

.int__pdf-search-results{
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  width: 90%;
}

.int__pdf-search-results-pages{
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  background: #d3d3d3;
  border: none;
  width: 30px;
  border-radius: 5px;
  cursor: pointer;
}
.int__body-pdf-pages-button{
  display: none;
}

/* Search Pdf responsive */
.int__pdf-search-resp-box-container{
  display: none;
}


@media screen and (max-width:1050px) {
  .int__pdf-document{
    /* display: none; */
  }
  .int__pdf-title{
    font-weight: 600;
    font-size: 20px;
  }
  .int__pdf-search-results-container{
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    width: 90%;
  }
  .int__pdf-search-results-container p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding: 1rem;
  }
  .int__pdf-search-results{
    width: 90%;
  }
  .int__pdf-search-results-pages{
    font-weight: 500;
    font-size: 11px;
    width: 23px;
  }
}

@media screen and (max-width:750px) {
  .int__pdf-title{
    font-weight: 600;
    font-size: 15px;
  }
  .int__pdf-pages-button img{
    height: 20px;
  }
  .int__pdf-lastpages-button img {
    height: 20px;
  }
  .int__pdf-pages {
    font-weight: 600;
    font-size: 12px;
    padding-bottom: 0rem;
  }
  .int__pdf-pages p {
    padding: 1rem;
  }
  .int__pdf-search-results-container{
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    width: 90%;
  }
  .int__pdf-search-results-container p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    padding: 0.8rem;
  }
  .int__pdf-search-results{
    width: 90%;
  }
  .int__pdf-search-results-pages{
    font-weight: 500;
    font-size: 11px;
    width: 23px;
  }

}

@media screen and (max-width:615px) {
  .int__body-pdf-pages-button {
    display: revert;
    font-family: var(--font-family);
    border-radius: 6px;
    background: #9A1413;
    color: white;
    border: none;
    padding: 0.5rem 0.5rem;
    width: 120px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 2px 2px 2px 1px #ccc;
    margin-top: 1rem;
  }
  .int__pdf-search-resp-box-container{
    display: block;
    width: 100%;
    padding-bottom: 1rem;
  }
  .int__pdf-search-resp-box{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }  
  .int__pdf-search-resp-box p{
    font-family: var(--font-family);
    font-style: normal;
    color: #9A1413;
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    padding-top: 0rem;
  }  
  .int__pdf-search-resp-box input{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 25px;
    width: 100%;
    background-color: #E5E5E5;
    border-radius: 5px 5px 5px 5px;
    border: 0;
    margin-left: 2rem;
    padding-left: 0.5rem;
  }
}