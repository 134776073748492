.int__modal_home-background{
    width: 100vw;
    height: 100vh;
    background-color: #9A9A9A90;;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    top:0;
}

.int__modal_home-container{
    width: 80%;
    height: 80%;
    position: "absolute";
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    z-index: 1000;
}

.int__modal_home-title-container{
    display: flex;
    justify-content: space-between;
}

.int__modal_home-title{
    font-family: var(--font-family);
    color: #9A1413;
}
.int__modal_home-closeButton{
    padding: 0.5rem 1rem;
    color: #9A1413;
    background-color: white;
    font-weight: 900;
    font-size: 20px;
    border: 0;
    outline: 0;
    cursor: pointer;
}

.int__modal_home-body{
    margin-top: 1rem;
    padding-right: 1rem;
    font-family: var(--font-family) !important;
    font-weight: 500;
    font-size: 16px !important;
    overflow: auto;
}

.int__modal_home-body p{
    margin-top: 1rem;
    font-size: 16px !important;
    font-family: var(--font-family) !important;
}

.int__modal_home-body a{
    color: #9A1413;
}

.int__modal_home-body ul{
    padding-left: 1.5rem;
}