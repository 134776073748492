.int__contenuti-navbar {
    min-height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: rgba(0,0,0,0.05);
}

.int__contenuti-navbar-horiz {
    min-height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1920px;
    padding: 0.3rem 7rem;
}

.int__contenuti-navbar-left-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.int__contenuti-navbar-right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.int__contenuti-navbar-left-container-box {
    text-align: left;
}

.int__contenuti-navbar-right-container-box {
    text-align: right;
}

.int__contenuti-pages-button {
    border: none;
    cursor: pointer;    
}

.int__contenuti-navbar-title{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #414141;
    margin-top: 0.2rem;
}

.int__contenuti-icons {
  max-height: 18px;
  max-width: 18px;
}

.int__contenuti-testo-container-vert {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.int__contenuti-testo-container-horiz{
    display: flex;
    flex-direction: column;
    padding: 1rem 7rem;
    max-width: 1920px;
    padding-bottom: 3em;
    width: 100%;
}

.int__contenuti-title-name {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 20px;
    color: #9A1413;
    margin-top: 1em;
    margin-bottom: 1em;
}

.int__contenuti-testo{
    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    font-size: 16px;
    color: #666;
    width: 100%;
}

.int__contenuti-testo p{
    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    font-size: 16px !important;
    margin-top: 1rem;
    color: #666;
}

.int__contenuti-testo span{
    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    font-size: 16px !important;
    margin-top: 1rem;
    color: #666;
}

.int__contenuti-testo b{
    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: bold;
    line-height: 28px;
    font-size: 16px !important;
    margin-top: 1rem;
    color: #666;
}

.int__contenuti-testo b span{
    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: bold;
    line-height: 28px;
    font-size: 16px !important;
    margin-top: 1rem;
    color: #666;
}

.int__contenuti-testo a{
    color: #9A1413;
}

.int__contenuti-testo ul{
    padding-left: 1.2rem !important;
}
.int__familiari-modal_elenco-link{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #9A1413;
    margin: 0.5rem 0rem 2rem;
    display: block;
}
.int__ricerca-familiari-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem 7rem;
}

.int__familiari-ricerca-numerorisultati{
    font-family: var(--font-family) !important;
    font-style: normal;
    padding: 1rem 0rem;
    color: #666;
}

.int__familiari_elenco{
    display: flex;
    flex-direction: column;
}

.int__familiari_item-result{
    display: flex;
    cursor: pointer;
    padding-bottom: 1rem
}

.int__familiari_item{
    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: 600;
    color: #9A1413;
    line-height: 15px;    
}

.int__familiari_item-link-ricerca{
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.int__familiari_item-link-ricerca img{
    height: 12px;
}

.int__ricerca-foto-container{
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin: 1rem 7rem;
}
.int__ricerca-foto-form-container{
    display: flex;
    flex-direction: column;
    margin: 1rem 0rem;
}
.int__ricerca-foto-form-container p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 20px;
    color: #9A1413;
}

.int__ricerca-foto-form{
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    align-items: center;
}

.int__ricerca-foto-form input {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    border: 0;
    padding: 1rem;
    background: #E5E5E5;
    width: 500px;
    border-radius: 5px 5px 5px 5px;
}

.int__ricerca-foto-form-submit{
    padding-left: 7rem;
}

.int__ricerca-foto-form-submit input {
    font-family: var(--font-family);
    border-radius: 6px;
    background: #9A1413;
    color: white;
    border: none;
    width: 150px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    box-shadow: 2px 2px 2px 1px #ccc;
}

.int__foto-ricerca-numerorisultati{
    font-family: var(--font-family) !important;
    font-style: normal;
    padding: 1rem 7rem;
    max-width: 1920px;
    color: #666;
}

.int__familiari-modal_home-background{
    width: 100vw;
    height: 100vh;
    background-color: #9A9A9A90;;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    top:0;
}

.int__familiari-modal_home-container{
    width: 70%;
    height: 70%;
    position: "absolute";
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    z-index: 1000;
}

.int__familiari-modal_home-title-container{
    display: flex;
    justify-content: space-between;
}

.int__familiari-modal_home-title{
    font-family: var(--font-family);
    color: #9A1413;
    font-size: 35px;
    font-weight: 700;
}
.int__familiari-modal_home-subtitle{
    font-family: var(--font-family);
    color: #111111;
    font-weight: 500 !important;
    font-size: 22px !important;
}
.int__familiari-modal_home-closeButton{
    padding: 0.5rem 1rem;
    color: #9A1413;
    background-color: white;
    font-weight: 900;
    font-size: 20px;
    border: 0;
    outline: 0;
    cursor: pointer;
}

.int__familiari-modal_home-body{
    margin-top: 1rem;
    padding-right: 1rem;
    font-family: var(--font-family) !important;
    font-weight: 500;
    font-size: 16px !important;
    overflow: auto;
}

.int__familiari-modal_home-body p{
    margin-top: 1rem;
    font-size: 16px !important;
    font-family: var(--font-family) !important;
}


@media screen and (max-width:1050px) {
    .int__contenuti-navbar{
        min-height: 70px;
    }
    .int__contenuti-navbar-horiz{
        min-height: 70px;
        width: 1920px;
        padding: 0.3rem 2rem;
    }
    .int__contenuti-navbar-title{
        font-weight: 500;
        font-size: 15px;
        margin-top: 0.2rem;
    }
    .int__contenuti-testo-container-horiz{
        padding: 1rem 2rem;
        padding-bottom: 3em;
        width: 100%;
    }
    .int__contenuti-title-name{
        font-weight: 600;
        font-size: 23px;
        line-height: 18px;
    }
    .int__contenuti-testo{
        font-weight: 400;
        line-height: 20px;
        font-size: 14px !important;
        width: 100%;
    }
    .int__contenuti-testo p{
        font-weight: 400;
        line-height: 20px;
        font-size: 14px !important;
        width: 100%;
    }
    .int__contenuti-testo span{
        font-weight: 400;
        line-height: 20px;
        font-size: 14px !important;
        width: 100%;
    }
    .int__contenuti-testo img {
        max-width: 250px;
    }
    .int__ricerca-familiari-container{
        margin: 1rem 2rem;
    }
    .int__ricerca-foto-container{
        margin: 1rem 2rem;
    }
    .int__ricerca-foto-form-container p {
        font-weight: 700;
        font-size: 20px;
        line-height: 18px;
    }
    .int__ricerca-foto-form input {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        padding: 1rem;
        width: 400px;
    }    
    .int__ricerca-foto-form-submit{
        padding-left: 5rem;
    }    
    .int__ricerca-foto-form-submit input {
        width: 120px;
        font-size: 18px;
        font-weight: 600;
    }
    .int__familiari-ricerca-numerorisultati{
        font-size: 15px;
    }    
    .int__foto-ricerca-numerorisultati{
        padding: 1rem 2rem;
        font-size: 15px;
        color: #666;
    }
    .int__familiari_item{
        font-weight: 500;
        font-size: 16px;
        line-height: 13px;
    }
    .int__familiari_item-link-ricerca img{
        height: 10px;
    }

    .int__familiari-modal_home-title{
        font-family: var(--font-family);
        color: #9A1413;
        font-size: 25px;;
    }    
    .int__familiari-modal_home-subtitle{
        font-family: var(--font-family);
        color: #111111;
        font-weight: 500 !important;
        font-size: 20px !important;
    }
    .int__familiari-modal_home-closeButton{
        padding: 0.5rem 1rem;
        font-weight: 900;
        font-size: 15px;
    }    
    .int__familiari-modal_home-body{
        margin-top: 1rem;
        padding-right: 1rem;
        font-size: 15px !important;
        overflow: auto;
    }
    .int__familiari-modal_home-body p{
        margin-top: 1rem;
        font-size: 15px !important;
        font-family: var(--font-family) !important;
    }
}

@media screen and (max-width:750px) {
    .int__contenuti-navbar{
        min-height: 60px;
    }
    .int__contenuti-navbar-horiz{
        min-height: 60px;
        width: 1920px;
        padding: 0.3rem 2rem;
    }
    .int__contenuti-navbar-title{
        font-weight: 500;
        font-size: 13px;
        margin-top: 0.2rem;
    }
    .int__contenuti-testo-container-horiz{
        padding: 1rem 2rem;
        padding-bottom: 3em;
        width: 100%;
    }
    .int__contenuti-title-name{
        font-weight: 600;
        font-size: 20px;
        line-height: 18px;
    }
    .int__contenuti-testo{
        font-weight: 500;
        line-height: 18px;
        font-size: 12px !important;
        width: 100%;
    }
    .int__contenuti-testo p{
        font-weight: 500;
        line-height: 18px;
        font-size: 12px !important;
        width: 100%;
    }
    .int__contenuti-testo span{
        font-weight: 500;
        line-height: 18px;
        font-size: 12px !important;
        width: 100%;
    }
    .int__contenuti-testo img {
        max-width: 200px;
    }
    .int__ricerca-familiari-container{
        margin: 1rem 2rem;
    }
    .int__ricerca-foto-container{
        margin: 1rem 2rem;
    }
    .int__ricerca-foto-form-container p {
        font-weight: 600;
        font-size: 17px;
        line-height: 16px;
    }
    .int__ricerca-foto-form input {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        padding: 0.5rem;
        width: 250px;
    }    
    .int__ricerca-foto-form-submit{
        padding-left: 2rem;
    }    
    .int__ricerca-foto-form-submit input {
        width: 60px;
        font-size: 13px;
        padding: 0.5rem 0.5rem;
        font-weight: 600;
    }
    .int__familiari-ricerca-numerorisultati{
        font-size: 12px;
    }    
    .int__foto-ricerca-numerorisultati{
        padding: 1rem 2rem;
        font-size: 12px;
        color: #666;
    }
    .int__familiari_item{
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
    }
    .int__familiari_item-link-ricerca{
        padding-left: 0.5rem;
    }
    .int__familiari_item-link-ricerca img{
        height: 8px;
    }
    
    .int__familiari-modal_home-title{
        font-family: var(--font-family);
        color: #9A1413;
        font-size: 20px;;
    }    
    .int__familiari-modal_home-subtitle{
        font-family: var(--font-family);
        color: #111111;
        font-weight: 500 !important;
        font-size: 15px !important;
    }
    .int__familiari-modal_home-closeButton{
        padding: 0.5rem 1rem;
        font-weight: 900;
        font-size: 12px;
    }    
    .int__familiari-modal_home-body{
        margin-top: 1rem;
        padding-right: 1rem;
        font-size: 12px !important;
        overflow: auto;
    }
    .int__familiari-modal_elenco-link{
        font-weight: 600;
        font-size: 16px;
        margin: 0.5rem 0rem 2rem;
    }
    .int__familiari-modal_home-body p{
        margin-top: 1rem;
        font-size: 12px !important;
        font-family: var(--font-family) !important;
    }
}

@media screen and (max-width:550px) {
    .int__contenuti-navbar{
        min-height: 60px;
    }
    .int__contenuti-navbar-horiz{
        min-height: 60px;
        width: 1920px;
        padding: 0.3rem 1.2rem;
    }
    .int__contenuti-navbar-title{
        font-weight: 500;
        font-size: 12px;
        margin-top: 0.2rem;
    }
    .int__contenuti-testo-container-horiz{
        padding: 1rem 1rem;
        padding-bottom: 3em;
        width: 100%;
    }
    .int__contenuti-title-name{
        font-weight: 600;
        font-size: 18px;
        line-height: 16px;
    }
    .int__contenuti-testo{
        font-weight: 500;
        line-height: 18px;
        font-size: 12px !important;
        width: 100%;
    }
    .int__contenuti-testo p{
        font-weight: 500;
        line-height: 17px;
        font-size: 12px !important;
        width: 100%;
    }
    .int__contenuti-testo span{
        font-weight: 500;
        line-height: 15px;
        font-size: 12px !important;
        width: 100%;
    }
    .int__contenuti-testo img {
        max-width: 140px;
    }
    .int__ricerca-familiari-container{
        margin: 1rem 1rem;
    }
    .int__ricerca-foto-container{
        margin: 1rem 1rem;
    }
    .int__ricerca-foto-form-container p {
        font-weight: 550;
        font-size: 15px;
        line-height: 14px;
    }
    .int__ricerca-foto-form input {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        padding: 0.5rem;
        width: 180px;
    }    
    .int__ricerca-foto-form-submit{
        padding-left: 2rem;
    }    
    .int__ricerca-foto-form-submit input {
        width: 50px;
        font-size: 12px;
        padding: 0.5rem 0.5rem;
        font-weight: 600;
    }
    .int__familiari-ricerca-numerorisultati{
        font-size: 12px;
    }    
    .int__foto-ricerca-numerorisultati{
        padding: 1rem 1rem;
        font-size: 12px;
        color: #666;
    }
    .int__familiari_item{
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
    }
    .int__familiari_item-link-ricerca{
        padding-left: 0.5rem;
    }
    .int__familiari_item-link-ricerca img{
        height: 7px;
    }   
    .int__familiari-modal_home-title{
        font-family: var(--font-family);
        color: #9A1413;
        font-size: 15px;;
    }    
    .int__familiari-modal_home-subtitle{
        font-family: var(--font-family);
        color: #111111;
        font-weight: 500 !important;
        font-size: 12px !important;
    }
    .int__familiari-modal_home-closeButton{
        padding: 0.2rem 0.2rem;
        font-weight: 900;
        font-size: 12px;
    }    
    .int__familiari-modal_home-body{
        margin-top: 1rem;
        padding-right: 1rem;
        font-size: 12px !important;
        overflow: auto;
    }
    .int__familiari-modal_elenco-link{
        font-weight: 600;
        font-size: 13px;
        margin: 0.5rem 0rem 1.5rem;
    }
    .int__familiari-modal_home-body p{
        margin-top: 1rem;
        font-size: 12px !important;
        font-family: var(--font-family) !important;
    }
}