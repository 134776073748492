.int__header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 80%; */
    /*min-height: 100px;*/
    padding-top: 10rem; 
}

.int__header-title-container {
    background: #9A9A9A80;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.int__header-title-main{
    flex:1;
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 700;
    font-size: 52px;
    color: #fff;
    text-transform: uppercase;
    margin-top: 1rem;
    margin-left: 10%;
}

.int__header-title-sub{
    flex:1;
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 400;
    font-size: 28px;
    margin-top: 1rem;
    margin-left: 10%;
    color: #FFFFFF;
}

.int__header-title-author{
    flex:1;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    margin-top: 2rem;
    margin-left: 10%;
    color: #FFFFFF;
}

.int__header-buttons-container{
    padding-top: 2rem;
    display: flex;
    justify-content: center;
}

.int__header-form-header-button {
    background: #9A1413;
    border: #9A1413;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px 5px 5px 5px;
    height: 60px;
    padding: 1rem 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    max-width:200px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.int__header-form-header-button p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF;
}

.int__header-form-container{
    flex:3;
    display: flex;
    justify-content: space-around;
    margin-top: 10rem;
    margin-bottom: 2rem;
}

.int__header-form-container-button {
    background: transparent;
    border: #fff;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px 5px 5px 5px;
    height: 60px;
    padding: 1rem 1rem;
    max-width:300px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.int__header-form-container-button p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF;
}

@media screen and (max-width:1050px) {
    .int__header-title-main{
        font-weight: 700;
        font-size: 40px;
        margin-top: 1rem;
        margin-left: 10%;
    }
    .int__header-title-sub{
        font-weight: 400;
        font-size: 22px;
        margin-top: 1rem;
        margin-left: 10%;
    }
    .int__header-title-author{
        font-weight: 400;
        font-size: 22px;
        margin-top: 2rem;
        margin-left: 10%;
    }
    .int__header-form-container-button{
        border-radius: 5px 5px 5px 5px;
        height: 40px;
        padding: 1rem 1rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        max-width: 200px;
    }
    .int__header-form-container-button p{
        font-weight: 400;
        font-size: 15px;
    }
    .int__header-form-header-button{
        height: 40px;
        padding: 1rem 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        max-width: 200px;
    }
    .int__header-form-header-button p{
        font-weight: 400;
        font-size: 15px;
    }
}

@media screen and (max-width:750px) {
    .int__header-title-main{
        font-weight: 700;
        font-size: 30px;
        margin-top: 1rem;
        margin-left: 10%;
    }
    .int__header-title-sub{
        font-weight: 400;
        font-size: 18px;
        margin-top: 1rem;
        margin-left: 10%;
    }
    .int__header-title-author{
        font-weight: 400;
        font-size: 18px;
        margin-top: 2rem;
        margin-left: 10%;
    }
    .int__header-form-container-button{
        border-radius: 5px 5px 5px 5px;
        height: 35px;
        padding: 1rem 1rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        max-width: 150px;
    }
    .int__header-form-container-button p{
        font-weight: 300;
        font-size: 12px;
    }
    .int__header-form-header-button{
        height: 35px;
        padding: 1rem 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        max-width: 150px;
    }
    .int__header-form-header-button p{
        font-weight: 300;
        font-size: 12px;
    }    
}

@media screen and (max-width:550px) {
    .int__header-title-main{
        font-weight: 700;
        font-size: 25px;
        margin-top: 1rem;
        margin-left: 10%;
    }
    .int__header-title-sub{
        font-weight: 500;
        font-size: 14px;
        margin-top: 1rem;
        margin-left: 10%;
    }
    .int__header-title-author{
        font-weight: 500;
        font-size: 15px;
        margin-top: 2rem;
        margin-left: 10%;
    }
    .int__header-form-container-button{
        border-radius: 5px 5px 5px 5px;
        height: 30px;
        padding: 0.5rem 0.5rem;
        max-width: 150px;
    }
    .int__header-form-container-button p{
        font-weight: 300;
        font-size: 9px;
    }
    .int__header-form-header-button{
        height: 30px;
        padding: 1rem 1rem;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
        max-width: 150px;
    }
    .int__header-form-header-button p{
        font-weight: 300;
        font-size: 9px;
    }
}