.int__sidemenu-main{
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
}
.int__sidemenu-title{
}
.int__sidemenu-title p{
    font-family: var(--font-family);
    font-style: normal;
    color: #9A1413;
    font-weight: 500;
    font-size: 15px;
    padding-top: 1rem;
}
.int__sidemenu-menu{
    padding: 1rem;
}

.int__sidemenu-menu-item{
    padding-top: 0.1rem;
}

.int__sidemenu-menu-item p{
    padding: 0.1rem;
    cursor: pointer;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
}

.int__sidemenu-menu-item p:hover{
    color: #9A1413;
}

@media screen and (max-width:1050px) {
    .int__sidemenu-title p{
        font-weight: 500;
        font-size: 13px;
        padding-top: 1rem;
    }
    .int__sidemenu-menu-item p{
        padding: 0.1rem;
        font-weight: 500;
        font-size: 13px;
    }
}

@media screen and (max-width:750px) {
    .int__sidemenu-title p{
        font-weight: 500;
        font-size: 11px;
        padding-top: 1rem;
    }
    .int__sidemenu-menu-item p{
        padding: 0.1rem;
        font-weight: 500;
        font-size: 11px;
    }
}
@media screen and (max-width:615px) {
    .int__sidemenu-main{
        display: none;
    }
}