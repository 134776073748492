.int__navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 20000; /* il valore più grande di tutti */
}

.int__navbar_home-background{
    width: 100vw;
    height: 100vh;
    background-color: #9A9A9A90;;
    position: absolute;
    left:0;
    z-index: 1000;
    top:0;
}

/* Menu principale */
.int__navbar-menu {
    display: flex;
    justify-content:flex-start;
    align-items: center;
}
.int__navbar-menu button {
    padding: 0rem 0.4rem;
    border-radius:5px;
    background-color: #9A1413;
    border: 0;
    outline: 0;
    cursor: pointer;
    text-align: center;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
}
.int__navbar-menu button p:hover{
    color: #fff;
}

.int__navbar-menu-indice{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    margin-top: 0;
    margin-bottom: 0.4rem;
}

.int__navbar-menu-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: start;
    background:#fff;
    padding: 1rem 1rem;
    position: absolute;
    left: 0;
    top: 0;
    min-width: 210px;
    height: 100vh;
}

.int__navbar-menu-frontespizio {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-left: 0.5rem;
}

.int__navbar-menu-frontespizio-logo img{
    max-width: 305px;
    max-height: 198px;
}

.int__navbar-menu-frontespizio-contenuti{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 1rem;
}

.int__navbar-menu-frontespizio-autore{
    font-family: var(--font-family);
    font-weight: 504;
    font-size: 24px;
}

.int__navbar-menu-frontespizio-titolo{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 30px;
}

.int__navbar-menu-frontespizio-sottotitolo{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 24px;
}

.int__navbar-menu-frontespizio-copyright{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    margin-top: 0.5rem;
}

.int__navbar-menu-frontespizio-isbn{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
}
.int__navbar-menu-frontespizio-resp-copyright{
    display: none;
}
.int__navbar-menu-frontespizio-resp-isbn{
    display: none;
}
.int__navbar-menu-frontespizio-hr{
    margin: 1.5rem;
    width: 93%;
    border-radius: 6px;
    border: 1px solid #9A9A9A80;
}
.int__navbar-mainmenu-container{
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 100%;
    overflow-y: scroll;
}

.int__navbar-mainmenu-container::-webkit-scrollbar {
    display: none;
  }

.int__navbar-menu-container p {
    color: #9a1413;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    margin: 0.5rem 1rem;
}

.int__navbar-menu ul {
    margin: 1rem 0rem;
}

.int__navbar-menu li p {
    color: grey;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
}
.int__navbar-menu p:hover {
    color: #000;
}

.int__navbar-mainmenu-final {
    margin-top: 3rem;
}

.int__navbar-mainmenu-final p {
    color: grey;
    line-height: 16px;
}


/* Titolo sito pagine contenuti*/
.int__navbar-site-title{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.int__navbar-site-title p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #9A1413;
    width: 100%;
    max-width: 1750px;
    margin-left: 2%;
}

/* Titolo sito pagine biografie*/
.int__navbar-site-title-bio{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.int__navbar-site-title-bio p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #9A1413;
    width: 100%;
    max-width: 1750px;
    margin-left: 2%;
}

/* Bottone Lingua */
.int__navbar-lang-button{
}
.int__navbar-lang-button button {
    padding: 0.5rem 1rem;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border-radius:5px;
    border: 0;
    outline: 0;
    cursor: pointer;
    background-color: #9A1413;
}
.int__navbar-lang-button p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    cursor: pointer;
    margin: 0 1rem;
}

/* Mobile Menu */
.int__navbar-mobile-menu {
    display: none;
    position: relative;
}
.int__navbar-mobile-menu button {
    padding: 0.5rem 1rem;
    border-radius:5px;
    background-color: #9A1413;
    border: 0;
    outline: 0;
    cursor: pointer;
}
.int__navbar-mobile-menu-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: start;
    background:#9A1413;
    padding: 0rem 1rem;
    position: absolute;
    left: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
}
.int__navbar-mobile-menu-container-menu p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    cursor: pointer;
    margin: 0 1rem;
}
.int__navbar-mobile-menu svg {
    cursor: pointer;
}
.int__navbar-mobile-menu-container-menu p {
    margin: 1rem 0;
}



@media screen and (max-width: 1050px) {
    .int__navbar {
        position: relative;
    }
    .int__navbar-site-title{
        font-weight: 600;
        font-size: 30px;
    }
    .int__navbar-site-title-bio p {
        font-weight: 600;
        font-size: 30px;
    }
    .int__navbar-menu button {
        padding: 0rem 0.1rem;
        border-radius:5px;
    }
    .int__navbar-menu-indice{
        font-size: 15px;
        line-height: 15px;
        margin-top: 0;
        margin-bottom: 0.2rem;
    }
    .int__navbar-menu-frontespizio {
        padding-left: 0.5rem;
    }
    .int__navbar-menu-frontespizio-logo img{
        max-width: 285px;
        max-height: 185px;
    }
    .int__navbar-menu-frontespizio-contenuti{
        margin-left: 1rem;
    }
    .int__navbar-menu-frontespizio-autore{
        font-family: var(--font-family);
        font-weight: 500;
        font-size: 20px;
    }
    .int__navbar-menu-frontespizio-titolo{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 27px;
    }
    .int__navbar-menu-frontespizio-sottotitolo{
        font-family: var(--font-family);
        font-weight: 500;
        font-size: 20px;
    }
    .int__navbar-menu-frontespizio-copyright{
        font-family: var(--font-family);
        font-weight: 500;
        font-size: 11px;
        margin-top: 0.5rem;
    }
    .int__navbar-menu-frontespizio-isbn{
        font-family: var(--font-family);
        font-weight: 500;
        font-size: 11px;
    }
    .int__navbar-menu-frontespizio-hr{
        margin: 1.2rem;
        width: 93%;
        border-radius: 6px;
        border: 1px solid #9A9A9A80;
    }
    .int__navbar-mainmenu-container p{
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
    }    
    .int__navbar-mainmenu-final{
        margin-top: 1rem;   
    }
    .int__navbar-menu li p {
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
        cursor: pointer;
    }
    
}

@media screen and (max-width: 750px) {
    .int__navbar-site-title{
        font-weight: 600;
        font-size: 25px;
    }
    .int__navbar-site-title-bio p {
        font-weight: 600;
        font-size: 25px;
    }
    .int__navbar-menu button {
        padding: 0rem 0.1rem;
        border-radius:5px;
    }
    .int__navbar-menu-indice{
        font-size: 12px;
        line-height: 12px;
        margin-top: 0;
        margin-bottom: 0.1rem;
    }
    .int__navbar-menu-frontespizio {
        padding-left: 0.5rem;
    }
    .int__navbar-menu-frontespizio-logo img{
        max-width: 255px;
        max-height: 165px;
    }
    .int__navbar-menu-frontespizio-contenuti{
        margin-left: 1rem;
    }
    .int__navbar-menu-frontespizio-autore{
        font-weight: 400;
        font-size: 14px;
    }
    .int__navbar-menu-frontespizio-titolo{
        font-weight: 600;
        font-size: 20px;
    }
    .int__navbar-menu-frontespizio-sottotitolo{
        font-weight: 500;
        font-size: 14px;
    }
    .int__navbar-menu-frontespizio-copyright{
        font-weight: 450;
        font-size: 9px;
        margin-top: 0.25rem;
        margin-bottom: 0.15rem;
    }
    .int__navbar-menu-frontespizio-isbn{
        font-weight: 450;
        font-size: 10px;
    }
    .int__navbar-menu-frontespizio-hr{
        margin: 1rem;
        width: 93%;
        border-radius: 6px;
        border: 1px solid #9A9A9A80;
    }
    .int__navbar-mainmenu-container p{
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
    }    
    .int__navbar-mainmenu-final{
        margin-top: 1rem;   
    }
    .int__navbar-menu li p {
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
        cursor: pointer;
    }
    .int__navbar-lang-button button {
        padding: 0.5rem 1rem;
        font-size: 13px;
        line-height: 18px;
    }
    .int__navbar-lang-button p {
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        margin: 0 0.5rem;
    }
}

@media screen and (max-width: 550px) {
    .int__navbar-site-title-bio p {
        font-weight: 600;
        font-size: 16px;
    }
    .int__navbar-menu button {
        padding: 0rem 0.1rem;
        border-radius:5px;
    }
    .int__navbar-menu-indice{
        font-size: 12px;
        line-height: 12px;
        margin-top: 0;
        margin-bottom: 0.1rem;
    }
    .int__navbar-menu-frontespizio {
        padding-left: 0.2rem;
    }
    .int__navbar-menu-frontespizio-logo img{
        max-width: 155px;
        max-height: 100px;
    }
    .int__navbar-menu-frontespizio-contenuti{
        justify-content: space-around;
        margin-left: 0.5rem;
    }
    .int__navbar-menu-frontespizio-autore{
        font-weight: 400;
        font-size: 12px;
    }
    .int__navbar-menu-frontespizio-titolo{
        font-weight: 600;
        font-size: 15px;
    }
    .int__navbar-menu-frontespizio-sottotitolo{
        font-weight: 500;
        font-size: 12px;
    }
    .int__navbar-menu-frontespizio-copyright{
        display: none;
    }
    .int__navbar-menu-frontespizio-isbn{
        display: none;
    }
    .int__navbar-menu-frontespizio-resp-copyright{
        display: flex;
        font-family: var(--font-family);
        font-weight: 450;
        font-size: 11px;
        margin-top: 0.25rem;
        margin-bottom: 0.18rem;
    }
    .int__navbar-menu-frontespizio-resp-isbn{
        display: flex;
        font-family: var(--font-family);
        font-weight: 450;
        font-size: 10px;
    }
    .int__navbar-menu-frontespizio-hr{
        margin: 0.5rem;
        width: 93%;
        border-radius: 6px;
        border: 1px solid #9A9A9A80;
    }
    .int__navbar-mainmenu-container p{
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
    }    
    .int__navbar-mainmenu-final{
        margin-top: 1rem;   
    }
    .int__navbar-menu li p {
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
        cursor: pointer;
    }
    .int__navbar-lang-button button {
        padding: 0.5rem 1rem;
        font-size: 13px;
        line-height: 18px;
    }
    .int__navbar-lang-button p {
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        margin: 0 0.5rem;
    }
}

