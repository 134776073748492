.int__sidebar{
    flex:3;
    margin-right: 0.5rem;
}
/* .int__sidebar-backbutton{
    padding-top: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    cursor: pointer;
}
.int__sidebar-backbutton img{
    max-height: 13px;
}
.int__sidebar-backbutton p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: #9A1413;
    padding-left: 0.5rem;
} 
.int__sidebar-hr{
    width: 100%;
    border-radius: 6px;
    border: 2px solid #9A1413;
    background-color: #9A1413;  
}*/

.int__sidebar-pdf-pages-button {
    font-family: var(--font-family);
    border-radius: 6px;
    background: #9A1413;
    color: white;
    border: none;
    padding: 0.5rem 0.5rem;
    width: 150px;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 2px 2px 2px 1px #ccc;
    margin-top: 1rem;
}

.int__sidebar-pdf-menu{
    display: flex;
    flex-direction: column;
}

.int__sidebar-abbreviazioni{
    padding-top: 1rem;
}

.int__sidebar-abbreviazioni p{
    padding: 0.1rem;
    cursor: pointer;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #000;
}

.int__sidebar-abbreviazioni p:hover{
    color: #9A1413;
}

  
@media screen and (max-width:1050px) {
    .int__sidebar-abbreviazioni{
        padding-top: 1rem;
    }
    .int__sidebar-abbreviazioni p{
        padding: 0.1rem;
        font-weight: 500;
        font-size: 13px;
    }
    .int__sidebar-pdf-pages-button {
        border-radius: 6px;
        padding: 0.5rem 0.5rem;
        width: 130px;
        font-size: 13px;
        font-weight: 500;
        margin-top: 1rem;
    }
}
  
  
@media screen and (max-width:750px) {
    .int__sidebar-abbreviazioni{
        padding-top: 1rem;
    }
    .int__sidebar-abbreviazioni p{
        padding: 0.1rem;
        font-weight: 500;
        font-size: 11px;
    }
    .int__sidebar-pdf-pages-button {
        border-radius: 6px;
        padding: 0.5rem 0.5rem;
        width: 130px;
        font-size: 11px;
        font-weight: 500;
        margin-top: 1rem;
    }
}

@media screen and (max-width:615px) {
    .int__sidebar{
        flex: 1;
        margin-right: 0rem;
    }
    .int__sidebar-pdf-menu{
        display: none;
    }
    .int__sidebar-pdf-pages-button {
        display: none;
    }
}