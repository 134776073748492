.int__circular-progressbar{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 2rem;
}

.int__circular-progressbar-element{
    width: 180px;
    display: flex;
    flex-direction: column;
}

.int__circular-progressbar-text{
    width: 100%;
    display: flex;
    justify-content: center;
}

.int__circular-progressbar-text p{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
}

@media screen and (max-width:1050px) {
    .int__circular-progressbar{
        display:flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 2rem;
    }
    .int__circular-progressbar-element{
        width: 110px;
    }
    .int__circular-progressbar-text{
        width: 100%;
        text-align: center;
    }
    .int__circular-progressbar-text p{
        font-weight: 600;
        font-size: 15px;
    }
}

@media screen and (max-width:750px) {
    .int__circular-progressbar{
        display:flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 1rem;
    }
    .int__circular-progressbar-element{
        width: 70px;
    }
    .int__circular-progressbar-text{
        width: 100%;
        text-align: center;
    }
    .int__circular-progressbar-text p{
        font-weight: 550;
        font-size: 12px;
    }
    
}

@media screen and (max-width:550px) {
    .int__circular-progressbar{
        display:flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 1rem;
    }
    .int__circular-progressbar-element{
        width: 50px;
    }
    .int__circular-progressbar-text{
        width: 100%;
        text-align: center;
    }
    .int__circular-progressbar-text p{
        font-weight: 550;
        font-size: 10px;
    }
    
}