.react-flow .react-flow__handle {
    width: 30px;
    height: 14px;
    border-radius: 3px;
    background-color: #000000;
  }
  
  .react-flow .react-flow__handle-top {
    top: -10px;
  }
  
  .react-flow .react-flow__handle-bottom {
    bottom: -10px;
  }
  
  .react-flow .react-flow__node {
    height: 40px;
    width: 150px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-width: 2px;
    font-weight: 700;
  }
  
  .react-flow .react-flow__edge path,
  .react-flow__connectionline path {
    stroke-width: 2;
  }
  
  .wrapper {
    flex-grow: 1;
    height: 100%;
  }
    
  .int__legenda_container {
    font-family: var(--font-family);
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

   .int__legenda_symbols {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
  }
  
  
  .int__legenda_item {
    display: flex;
    align-items: center;
    padding-left: 1rem;
  }

  .int__legenda_colors{
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
  }

  .react-flow__attribution {
    font-size: 0px !important;
}

@media screen and (max-width:1050px) {
  
}

@media screen and (max-width:750px) {
  .int__legenda_item {
    font-size: 10px;
  }

}

@media screen and (max-width:615px) {
  .int__content-grafo {
    margin-bottom: 2rem;
  }
  .int__legenda_container {
    margin-bottom: 0rem;
  }
  .int__legenda_item {
    font-size: 8px;
  }
  .int__legenda_symbols {
    flex-direction: column;
    justify-content:space-between;
  }
  .int__legenda_item {
    display: flex;
    align-items: center;
    padding-top: 1rem;
  }
  .int__legenda_colors{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
  }
}