
.int__mappa-title {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    color: #4f4f4f;
    padding-bottom: 1rem;
    padding-top: 2rem;
}

.int__mappa-container {
    position:'relative';
    display: flex;
    flex-direction:'column';
    align-items: 'center';
    height: 50vh;
    width: 100%;
    margin-top:1rem;
}

.int__mappa-map{
    position:'absolute';
    margin-left:0rem;
    margin-top:1rem;
    height:100%;
    width:100%;
}

.leaflet-container {
    width: 100%;
    height: 100%;
}


@media screen and (max-width:1050px) {
    .int__mappa-title {
        font-weight: 600;
        font-size: 20px;
        padding-bottom: 1rem;
        padding-top: 2rem;
    }
}

@media screen and (max-width:750px) {
    .int__mappa-title {
        font-weight: 600;
        font-size: 17px;
        padding-bottom: 1rem;
        padding-top: 2rem;
    }
    .leaflet-container {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width:550px) {
     
}