.int__relazioni-main {
  align-items: center;
  justify-content: center;
}

.int__relazioni-title {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  color: #4f4f4f;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.int__relazioni-hr {
  width: 100%;
  border-radius: 6px;
  border: 2px solid #9a1413;
}

.int__relazioni-box {
  padding: 0.5rem;
}
.int__relazioni-text {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
}

.int__relazioni-text a {
  font-weight: 450;
  color: #9a1413;
}

.int__viteconnesse-link {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #9a1413;
  margin: 0.5rem 0rem;
  text-transform: capitalize;
  display: block;
  cursor: pointer;
}

.int__viteconnesse-no-link {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #a5a1a1;
  margin: 0.5rem 0rem;
  text-transform: capitalize;
  display: block;
}

@media screen and (max-width: 1050px) {
  .int__relazioni-title {
    font-weight: 600;
    font-size: 20px;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .int__viteconnesse-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    margin: 0.5rem 0rem;
  }

  .int__viteconnesse-no-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    margin: 0.5rem 0rem;
  }
  .int__relazioni-box {
    padding: 0.5rem;
  }
  .int__relazioni-text {
    font-weight: 300;
    font-size: 16px;
  }
  .int__relazioni-text a {
    font-weight: 450;
  }
}

@media screen and (max-width: 750px) {
  .int__relazioni-title {
    font-weight: 600;
    font-size: 15px;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .int__viteconnesse-link {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin: 0.5rem 0rem;
  }

  .int__viteconnesse-no-link {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin: 0.5rem 0rem;
  }
  .int__relazioni-box {
    padding: 0.5rem;
  }
  .int__relazioni-text {
    font-weight: 300;
    font-size: 14px;
  }
  .int__relazioni-text a {
    font-weight: 450;
  }
}

@media screen and (max-width: 615px) {
  .int__relazioni-title {
    font-weight: 600;
    font-size: 17px;
    padding-bottom: 0.3rem;
    padding-top: 1.5rem;
  }
  .int__relazioni-text ul {
    padding-left: 0.2rem;
  }
  .int__viteconnesse-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin: 0.5rem 0rem;
  }

  .int__viteconnesse-no-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin: 0.5rem 0rem;
  }
  .int__relazioni-text {
    font-weight: 300;
    font-size: 14px;
  }
  .int__relazioni-text a {
    font-weight: 450;
  }
}
