.int__ricercaavanzata-container{
    margin: 2rem 0rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.int__ricercaavanzata-labels{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #4f4f4f;
    padding-left: 1rem;
}

.int__ricercaavanzata-labels select{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    border: 0;
    margin-top: 0.5rem;
    padding: 0.2rem 0.6rem;
    background: #E5E5E5;
    border-radius: 5px 5px 5px 5px;
}

.int__ricercaavanzata-button{
    font-family: var(--font-family);
    border-radius: 6px;
    background: #9A1413;
    color: white;
    border: none;
    padding: 0.5rem 0.5rem;
    width: 150px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    box-shadow: 2px 2px 2px 1px #ccc;
}

.int__ricercaavanzata-numerorisultati{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    padding: 1rem 0rem;
}

@media screen and (max-width:1050px) {
    .int__ricercaavanzata-container{
        margin: 0rem 0rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .int__ricercaavanzata-labels{
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        padding-left: 0rem;
        padding-top: 1.5rem;
    }
    .int__ricercaavanzata-labels select{
        font-size: 13px;
    }
    .int__ricercaavanzata-button{
        margin-top: 2rem;
        padding: 0.5rem;
        width: 150px;
        font-size: 18px;
        line-height: 20px;
        font-style: normal;
        font-weight: 700;
    }
    .int__ricercaavanzata-numerorisultati {
        font-size: 15px;
    }
}

@media screen and (max-width:750px) {
    .int__ricercaavanzata-container{
        margin: 0rem 0rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .int__ricercaavanzata-labels{
        font-weight: 600;
        font-size: 15px;
        line-height: 15px;
        padding-left: 0rem;
        padding-top: 1.5rem;
    }
    .int__ricercaavanzata-labels select{
        font-size: 12px;
    }
    .int__ricercaavanzata-button{
        margin-top: 2rem;
        padding: 0.5rem;
        width: 80px;
        font-size: 15px;
        line-height: 20px;
        font-style: normal;
        font-weight: 700;
    }
    .int__ricercaavanzata-numerorisultati {
        font-size: 15px;
    }
}

@media screen and (max-width:550px) {
    .int__ricercaavanzata-container{
        margin: 0rem 0rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .int__ricercaavanzata-labels{
        font-weight: 600;
        font-size: 13px;
        line-height: 13px;
        padding-left: 0rem;
        padding-top: 1.5rem;
    }
    .int__ricercaavanzata-labels select{
        font-size: 11px;
    }
    .int__ricercaavanzata-button{
        margin-top: 2rem;
        padding: 0.5rem;
        width: 80px;
        font-size: 13px;
        line-height: 20px;
        font-style: normal;
        font-weight: 700;
    }
    .int__ricercaavanzata-numerorisultati {
        font-size: 13px;
    }
}