.capitalize {
    text-transform: capitalize;
}
.ml-1 {
    margin-left: 0.25rem;
}
.int__elenco-global-container-vert {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.int__elenco-global-container-horiz{
    display: flex;
    flex-direction: column;
    width: 1920px;
    padding: 1rem 7rem;
}
.int__elenco-container{
    width: 100%;
}
.int__elenco-form-container{
    display: flex;
    flex-direction: column;
    margin: 1rem 25.7%;
}
.int__elenco-form-container p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 20px;
    color: #9A1413;
    padding-left: 1rem;
}

.int__elenco-form{
    display: flex;
    flex-direction: row;
    padding: 1rem;
    align-items: center;
}

.int__elenco-form input {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    border: 0;
    padding: 1rem;
    background: #E5E5E5;
    width: 500px;
    border-radius: 5px 5px 5px 5px;
}

/* Elenco */
.int__elenco-letterecontainer{
    padding-top: 2rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.int__elenco-lettere-button{
    width: 30px;
    display: flex;
    justify-content: center;
    border-radius: 2px;
    background: #9A1413;
    color: white;
    border: none;
    margin-left: 0.3rem;
    padding: 0.5rem 0.5rem;
    cursor: pointer;
}

.int__elenco-lettere-button p{
    font-family: var(--font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.int__elenco-elenco{
    padding: 1rem 1rem;
}

.int__elenco-elenco-iniziale{
    display: flex;
    flex-direction: column;
    padding: 1rem 0rem;
}

.int__elenco-elenco-lettera{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 20px;
    color: #9A1413;
    padding: 1rem 0rem;
    text-transform: capitalize;
}
.int__elenco-hr{
    width: 100%;
    background-color:#9A1413;
    border-radius: 4px;
    border: 1px solid #9A1413;
    margin-bottom: 1.5rem;
}

.int__elenco-item-elenco-link{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #9A1413;
    margin: 0.5rem 0rem;
    text-transform: capitalize;
    display: block; 
}
.int__elenco-item-elenco-no-link{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 39px;
    color: #a5a1a1;
    margin: 0.5rem 0rem;
    text-transform: capitalize;
    display: block; 
}

.int__elenco-item-elenco-alias{
    display: flex;
    flex-direction: row;
    line-height: 30px;
    margin: 0.5rem 0rem;
}

.int__elenco-item-elenco-alias-no-link{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #a5a1a1;
    display: block; 
}

.int__elenco-item-elenco-alias-link{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #9A1413;
    text-transform: capitalize;
    display: block; 
}

.int__elenco-elenco-item-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 30px;
}

.int__elenco-elenco-item-container:hover{
    background-color:#E5E5E5;
}

.int__elenco-item-evidenza{
    margin: 0.5rem 0.5rem;
}

.int__elenco-item-evidenza img{
    max-width: 11px;
}

@media screen and (max-width:1050px) {
    .int__elenco-global-container-horiz {
        padding: 1rem 2rem;
    }
    .int__contenuti-testo-container-horiz{
        padding-bottom: 1em;
    }
    .int__elenco-letterecontainer{
        padding-top: 1.5rem;
        padding-bottom: 1rem;
    }
    .int__elenco-lettere-button{
        width: 25px;
        margin-left: 0.3rem;
        padding: 0.5rem 0.5rem;
    }
    .int__elenco-lettere-button p{
        font-size: 15px;
        font-weight: 600;
    }
    .int__elenco-elenco-iniziale{
        padding: 0.5rem 0rem;
    }
    
    .int__elenco-elenco-lettera{
        font-weight: 500;
        font-size: 25px;
        line-height: 20px;
        padding: 1rem 0rem;
    }
    .int__elenco-hr{
        width: 100%;
        border-radius: 4px;
        margin-bottom: 1rem;
    }
    .int__elenco-form-container{
        margin: 1rem 25.7%;
    }
    .int__elenco-form-container p {
        font-weight: 700;
        font-size: 22px;
        line-height: 20px;
        padding-left: 1rem;
    }
    .int__elenco-form{
        padding: 1rem;
    }
    .int__elenco-form input {
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
        border: 0;
        padding: 1rem;
        width: 500px;
    }
    .int__elenco-item-elenco-no-link {
        font-weight: 600;
        font-size: 15px;
        line-height: 27px;
        margin-top: 0.65rem;
    }
    .int__elenco-elenco-item-container {
        line-height: 20px;
    }
    .int__elenco-item-elenco-link {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        margin: 0.5rem 0rem;
    }
    .int__elenco-item-evidenza {
        margin: 0.5rem 0.5rem;
    }
    .int__elenco-item-evidenza img{
        max-width: 10px;
    }
    .int__elenco-item-elenco-alias {
        line-height: 20px;
        margin: 0.5rem 0rem;
    }
    .int__elenco-item-elenco-alias-no-link {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
    }
    .int__elenco-item-elenco-alias-link {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
    }
}

@media screen and (max-width:750px) {
    .int__elenco-global-container-horiz {
        padding: 1rem 1rem;
    }
    .int__contenuti-testo-container-horiz{
        padding-bottom: 1em;
    }
    .int__elenco-letterecontainer{
        padding-top: 0.5rem;
        padding-bottom: 0rem;
        flex-wrap: wrap;
        margin: 10px;
    }
    .int__elenco-lettere-button{
        width: 21px;
        margin-left: 0.5rem;
        padding: 0.5rem 0.5rem;
        cursor: pointer;
        margin-top: 0.5rem;
    }
    .int__elenco-lettere-button p{
        font-size: 14px;
        font-weight: 600;
    }
    .int__elenco-elenco-lettera{
        font-weight: 500;
        font-size: 20px;
        line-height: 18px;
        padding: 0.5rem 0rem;
    }
    .int__elenco-hr{
        width: 100%;
        border-radius: 3px;
        margin-bottom: 0.5rem;
    }
    .int__elenco-elenco-iniziale{
        padding: 0.5rem 0rem;
    }
    .int__elenco-form-container{
        margin: 0rem 25.7%;
    }
    .int__elenco-form-container p {
        font-weight: 700;
        font-size: 20px;
        line-height: 18px;
        padding-left: 1rem;
    }
    .int__elenco-form{
        padding: 1rem;
    }
    .int__elenco-form input {
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
        padding: 0.5rem;
        width: 350px;
    }
    .int__elenco-item-elenco-no-link {
        font-weight: 550;
        font-size: 15px;
        line-height: 22px;
        margin-top: 0.22rem;
        margin-bottom: 0.22rem;
    }
    .int__elenco-elenco-item-container {
        line-height: 18px;
    }
    .int__elenco-item-elenco-link {
        font-weight: 550;
        font-size: 15px;
        line-height: 22px;
        margin: 0.2rem 0rem;
    }
    .int__elenco-item-evidenza {
        margin: 0.22rem 0.5rem;
    }
    .int__elenco-item-evidenza img{
        max-width: 8px;
    }
    .int__elenco-item-elenco-alias {
        line-height: 20px;
        margin: 0.22rem 0rem;
    }
    .int__elenco-item-elenco-alias-no-link {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
    }
    .int__elenco-item-elenco-alias-link {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
    }    
}

@media screen and (max-width:550px) {
    .int__elenco-global-container-horiz {
        padding: 1rem 0rem;
    }
    .int__contenuti-testo-container-horiz{
        padding-bottom: 1em;
    }
    .int__elenco-letterecontainer{
        flex-wrap: wrap;
    }
    .int__elenco-elenco-lettera{
        font-weight: 500;
        font-size: 17px;
        line-height: 15px;
        padding: 0.5rem 0rem;
    }
    .int__elenco-hr{
        width: 100%;
        border-radius: 3px;
        margin-bottom: 0.5rem;
    }
    .int__elenco-elenco-iniziale{
        padding: 0.5rem 0rem;
    }
    .int__elenco-form-container{
        margin: 0rem 25.7%;
    }
    .int__elenco-form-container p {
        font-weight: 700;
        font-size: 12px;
        line-height: 13px;
        padding-left: 1rem;
    }
    .int__elenco-form{
        padding: 1rem;
    }
    .int__elenco-form input {
        font-weight: 400;
        font-size: 8px;
        line-height: 12px;
        padding: 0.5rem;
        width: 100%;
    }
    .int__elenco-item-elenco-no-link {
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;
    }
    .int__elenco-elenco-item-container {
        line-height: 22px;
    }
    .int__elenco-item-elenco-link {
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        margin: 0.2rem 0rem;
    }
    .int__elenco-item-evidenza {
        margin: 0.2rem 0.5rem;
    }
    .int__elenco-item-evidenza img{
        max-width: 5px;
    }
    .int__elenco-item-elenco-alias {
        line-height: 22px;
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
    .int__elenco-item-elenco-alias-no-link {
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
    }
    .int__elenco-item-elenco-alias-link {
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
    }
    
     
}
