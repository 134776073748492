.int__pdf-search-box-container{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
  }
  .int__pdf-search-box{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
  }
  
  .int__pdf-search-box p{
    font-family: var(--font-family);
    font-style: normal;
    color: #9A1413;
    font-weight: 500;
    font-size: 15px;
    padding-top: 0rem;
    padding-bottom: 1rem;
  }
  
  .int__pdf-search-box input{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    width: 85%;
    background-color: #E5E5E5;
    border-radius: 5px 5px 5px 5px;
    border: 0;
    padding-left: 1rem;
  }

  
  @media screen and (max-width:1050px) {
    .int__pdf-search-box{
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 100%;
    }
    
    .int__pdf-search-box p{
      font-weight: 500;
      font-size: 13px;
      padding-top: 0rem;
      padding-bottom: 1rem;
    }
    
    .int__pdf-search-box input{
      font-weight: 400;
      font-size: 12px;
      line-height: 26px;
      width: 85%;
    }
  }
  
  @media screen and (max-width:750px) {
    .int__pdf-search-box{
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 100%;
    }
    
    .int__pdf-search-box p{
      font-weight: 500;
      font-size: 11px;
      padding-top: 0rem;
      padding-bottom: 1rem;
    }
    
    .int__pdf-search-box input{
      font-weight: 400;
      font-size: 11px;
      line-height: 22px;
      width: 85%;
    }  
  }
  
  @media screen and (max-width:550px) {
       
  }