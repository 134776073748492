.int__triangle-node {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 30px 50px 30px;
  border-color: transparent transparent #E5E5E5 transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}
  
.int__big-triangle-node {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 70px 100px 70px;
  border-color: transparent transparent #E5E5E5 transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.int__red-triangle-node {
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 0 30px 50px 30px;
  border-color: transparent transparent #9a1413 transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.int__big-red-triangle-node {
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 0 70px 100px 70px;
  border-color: transparent transparent #9a1413 transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.int__grey-triangle-node {
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 0 30px 50px 30px;
  border-color: transparent transparent #4f4f4f transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.int__big-grey-triangle-node {
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 0 70px 100px 70px;
  border-color: transparent transparent #4f4f4f transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.int__circle-node {
  background-color: #E5E5E5;
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 15px 15px 15px 15px;
  border-color: transparent transparent transparent transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.int__big-circle-node {
  background-color: #E5E5E5;
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 50px 50px 50px 50px;
  border-color: transparent transparent transparent transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.int__red-circle-node {
  background-color: #9a1413;
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 15px 15px 15px 15px;
  border-color: transparent transparent #9a1413 transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.int__grey-circle-node {
  background-color: #4f4f4f;
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 10px 10px 10px 10px;
  border-color: transparent transparent #4f4f4f transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.int__big-red-circle-node {
  background-color: #9a1413;
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 50px 50px 50px 50px;
  border-color: transparent transparent #9a1413 transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.int__big-grey-circle-node {
  background-color: #4f4f4f;
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 50px 50px 50px 50px;
  border-color: transparent transparent #4f4f4f transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.int__square-node {
  background-color: #E5E5E5;
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 10px 10px 10px 10px;
  border-color: transparent transparent transparent transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.int__big-square-node {
  background-color: #E5E5E5;
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 50px 50px 50px 50px;
  border-color: transparent transparent transparent transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.int__red-square-node {
  background-color: #9a1413;
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 10px 10px 10px 10px;
  border-color: transparent transparent #9a1413 transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.int__grey-square-node {
  background-color: #4f4f4f;
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 10px 10px 10px 10px;
  border-color: transparent transparent #4f4f4f transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.int__big-red-square-node {
  background-color: #9a1413;
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 50px 50px 50px 50px;
  border-color: transparent transparent #9a1413 transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.int__big-grey-square-node {
  background-color: #4f4f4f;
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 50px 50px 50px 50px;
  border-color: transparent transparent #4f4f4f transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.int__dot-node {
  width: 15px;
  height: 15px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: black black black black;
  float: left;
}