.int__gallery-main {
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}

.int__gallery-title {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #9a1413;
  text-align: left;
}
.int__gallery-image {
  width: 95%;
  padding: 0.1rem;
  object-fit: cover;
}
.int__gallery-image-box {
  position: relative;
  text-align: left;
  cursor: pointer;
  padding: 0rem 1rem;
  padding-top: 1rem;
  max-width: 490px;
}

.int__gallery-image-box__ui_freccia {
  position: absolute;
  left: 85%;
  top: 50%;
  width: 9%;
  transform: translate(0, -50%);
}

.int__gallery-legend {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}

.css-tlc64q-MuiPaper-root-MuiDialog-paper {
  background-color: #fff !important;
}
.int__gallery-slide_image {
  max-height: 100%;
}

.control-prev:before {
  border-right: 8px solid #9a1413 !important;
}

.control-next:before {
  border-left: 8px solid #9a1413 !important;
}

.image-gallery-icon:hover {
  color: #9a1413 !important;
}
.image-gallery-bullet:hover {
  background: #9a1413 !important;
  border: 1px solid #9a1413 !important;
}

.image-gallery-slide .image-gallery-description {
  background: rgba(0, 0, 0, 0.4);
  bottom: 0px !important;
  color: #000;
  left: 45%;
  line-height: 1;
  padding: 10px 20px;
  white-space: normal;
  justify-content: center;
  align-items: center;
}

.image-gallery-bullets {
  top: 10px !important;
}

.image-gallery-fullscreen-button {
  top: 10px !important;
  bottom: unset !important;
}

@media screen and (max-width: 1050px) {
  .int__gallery-title {
    font-weight: 500;
    font-size: 13px;
  }
}

@media screen and (max-width: 750px) {
  .int__gallery-image-box {
    padding: 0rem 1rem;
    padding-top: 1rem;
  }
}

@media screen and (max-width: 550px) {
  .int__gallery-title {
    font-weight: 500;
    font-size: 15px;
  }
}
